<template>
    <!-- User Profile Personal Basic Info Tab Content View From Account Profile -->
    <div class="button-padding">
        <div class="tabs-content-secondblock">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ml-2 mr-1 mb-3">
                        <div class="row" v-if="ajax_call_in_progress">
                            <div class="col-lg-12 py-5 px-3 mt-3">
                                <div class="flex-container">
                                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="infoHeader px-3 pb-4 mt-6">
                                    <div class="create-company-header d-flex align-items-center justify-content-between">
                                        <span class="fs-14 text-white pl-4">Company Information</span>
                                        <span class="pointer" v-if="!edit_enable">
                                            <div style="margin-right: 10px;" @click="editEnable">
                                                <!-- <el-tooltip class="item" effect="dark" content="Edit" placement="top"> -->
                                                    <button class="btn btn-icon pointer ml-2 mr-2 text-capitalize" style="padding: 5px 6px 0 6px !important;background: rgb(255, 255, 255);border-radius: 3px !important;">
                                                        <i class="icon icon-pencil pointer fs-16" style="color: #00448b !important;"></i>
                                                        <!-- <img src="../../assets/Icon material-edit.svg" height="12px;" style="margin-top: -3px;"> -->
                                                        <span class="mx-1 mt-1" style="color: #00448b;font-size: 14px;font-weight: 600;">Edit</span>
                                                    </button>
                                                <!-- </el-tooltip> -->
                                            </div>
                                            <!-- <i class="text-white" :class="{'icon-chevron-double-down':company_info_expand, 'icon-chevron-double-up':!company_info_expand}" style="font-size: 20px;"></i> -->
                                        </span>
                                    </div>
                                    <div v-if="company_info_expand" style="padding: 0 10px;">
                                        <!-- <div class="row">
                                            <div class="col-12">
                                                <div>
                                                    <img style="border-radius: 50%;" :src="comp_data.logo || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="50px" height="50px" />
                                                </div>
                                                <div>
                                                    <p class="fs-14 text-white fw-600 mb-0 text-capitalize">{{comp_data.company_name || "-"}}</p>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Company Name</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_company_name" >
                                                        <div class="pointer" @click="resetValue('company_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('company_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant' "
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='company_name' class="input-field-underline" :class="{'pr-15':edit_company_name}" @focus="editUser('company_name')" style="padding-top: 6px !important;" :readonly="!edit_company_name" maxlength="100" v-model="edit_user_data.company_name">
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="mt-3">
                                                <div class="d-flex align-items-center new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Domain Name</div>
                                                    <div class="w-100" style="position: relative;">
                                                        <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_domain_name" >
                                                            <div class="pointer" @click="resetValue('domain_name')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="updateInfo('domain_name')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div> -->
                                                        <!-- <el-tooltip class="item" effect="dark" :disabled="true"
                                                                content="Click to edit"
                                                                placement="top">
                                                        <input name='domain_name' class="input-field-underline" style="padding-top: 6px !important;" :readonly="true" v-model="edit_user_data.domain_name">
                                                        </el-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div> -->
                                        <div class="row mt-5">
                                            <div class="col-lg-12 col-md-12 col-sm-12"  style="text-align: center;">
                                                <div v-loading="logo_image_upload_inprogress" class="avatar-wrapper position-relative mx-auto" style="width: 67px;">
                                                    <img style="border-radius: 50%; border: 2px solid #ecf1f9;" :src="edit_user_data.logo || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="70" height="70" />
                                                    <!-- <div class="profilePicEdit-parent pointer" v-if="edit_enable" >
                                                        <label class="profilePicEdit m-0 pointer" for="fileprofile">
                                                            <img style="border-radius: 50%;" width="20" src="/static/images/editgreynew.svg">
                                                        </label>
                                                        <input type="file" style="display: none;" ref="myProfilePic"
                                                                accept="image/x-png, image/gif, image/jpeg" id="fileprofile"
                                                                @change="onUserProfile" />
                                                    </div> -->
                                                </div>
                                                <div v-if="edit_enable" class="d-flex align-items-center justify-content-center mt-3">
                                                    <button class="btn btn-new-secondary text-capitalize px-2" :disabled="save_api_call_in_progress || logo_image_upload_inprogress" style="height: unset;line-height: 2.5;" type="button" @click="triggerFileUpload">
                                                        <i class="icon icon-pencil text-blue fs-16"></i>
                                                        Change
                                                        <input type="file" style="display: none;" ref="myProfilePic"
                                                                accept="image/x-png, image/png, image/gif, image/jpeg, image/jpg" id="fileprofile"
                                                                @change="onUserProfile" />
                                                    </button>
                                                    <button class="btn btn-danger text-capitalize ml-5 px-2" :disabled="save_api_call_in_progress || logo_image_upload_inprogress || edit_user_data.logo == ''" @click="removeCompanyImage" style="border-radius: 5px;height: unset;line-height: 2.5;" type="button">
                                                        <i class="icon icon-delete text-danger fs-16"></i>
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-5">
                                            <div class="col-lg-12 col-md-12 col-sm-12"  style="text-align: center;">
                                                <span class="fs-14 fw-600" style="color: #00448b;">{{edit_user_data.company_name}}</span>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-lg-12 col-md-12 col-sm-12"  style="text-align: center;">
                                                <span class="fs-14">Domain Name :  {{edit_user_data.domain_name}}</span>
                                            </div>
                                        </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-5">
                                                <div class="d-flex align-items-center new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Industry Type</div>
                                                    <div class="w-100" style="position: relative;">
                                                        <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_industry_type" >
                                                            <div class="pointer" @click="resetValue('industry_type')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="updateInfo('industry_type')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div> -->
                                                        <div v-if="edit_enable" class="mr-12" style="width: 100%;">
                                                            <multiselect class="diginew-multiselect diginew-multiselect-underline multiselect-color" name="meetingcall" :show-labels="false" :searchable="true" :loading="loading" :options="industry_type_options" @open="fetchDropdownsData('industry_type')" v-model="edit_user_data.industry_type" placeholder="Select Industry Type" style="padding-bottom: 0px !important">
                                                                <template slot="noOptions">
                                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                                </template>
                                                                <template slot="noResult">
                                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                </template>
                                                                <template slot="afterList">
                                                                    <div v-observe-visibility="reachedEndOfListDropdown" />
                                                                </template>
                                                            </multiselect>
                                                        </div>
                                                        <div v-if="!edit_enable">
                                                            <input class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.industry_type">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-5">
                                                <div class="d-flex align-items-center new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">
                                                        Date of Incorporation
                                                    </div>
                                                    <div class="w-100" >
                                                        <div style="position: relative; width: 100%;">
                                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="z-index: 2;right: -40px;bottom: 10px;" v-if="edit_date_of_incorporation" >
                                                                <div class="pointer" @click="resetValue('date_of_incorporation')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                                </div>
                                                                <div class="ml-2 pointer" @click="updateInfo('date_of_incorporation')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                                </div>
                                                            </div> -->
                                                            <div v-if="edit_enable">
                                                                <date-picker class="profile-date-picker mx-input-date calendar-edit-per input-field-underline pt-0 pb-0 pr-0" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant' || !edit_enable" v-model="edit_user_data.date_of_incorporation" value-type="format" :popup-style="{ top: '100%', left: 0}" :append-to-body="false"
                                                                :clearable="false"
                                                                :editable="false"
                                                                lang="en" :not-after="new Date()" type="date" format="DD/MM/YYYY" style="width:100% !important"></date-picker>
                                                            </div>
                                                            <div v-if="!edit_enable">
                                                                <input class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.date_of_incorporation">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-7">
                                                <div class="d-flex align-items-center new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Number of Employees</div>
                                                    <div class="w-100" style="position: relative;">
                                                        <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_no_of_employees" >
                                                            <div class="pointer" @click="resetValue('no_of_employees')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="updateInfo('no_of_employees')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div> -->
                                                        <input name='no_of_employees' @keypress="blockAlphabets" class="input-field-underline" v-validate="'required|numeric'" maxlength="100" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.no_of_employees">
                                                        <div class="text-right" v-if="edit_enable" style="position: absolute;right: 0px;">
                                                            <span class="input-field-length">{{ edit_user_data.no_of_employees.length }}/100</span>
                                                        </div>
                                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('no_of_employees')">
                                                            {{errors.first('no_of_employees')}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-7">
                                                <div class="d-flex align-items-center new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Currency</div>
                                                    <div class="w-100" style="position: relative;">
                                                        <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_currency" >
                                                            <div class="pointer" @click="resetValue('currency')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="updateInfo('currency')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div> -->
                                                        <div v-if="edit_enable" class="mr-12" style="width: 100%;">
                                                            <multiselect class="diginew-multiselect diginew-multiselect-underline multiselect-color" label="label" v-validate="'required'" :show-labels="false" name="currency" :searchable="true" :options="currency_options" v-model="edit_user_data.currency" placeholder="Select Currency" style="padding-bottom: 0px !important">
                                                                <template slot="noOptions">
                                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                                </template>
                                                                <template slot="noResult">
                                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                </template>
                                                            </multiselect>
                                                        </div>
                                                        <div v-if="!edit_enable">
                                                            <input class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.currency.label">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-7">
                                                <div class="d-flex align-items-center new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Time Zone</div>
                                                    <div class="w-100" style="position: relative;">
                                                        <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_time_zone" >
                                                            <div class="pointer" @click="resetValue('time_zone')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="updateInfo('time_zone')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div> -->
                                                        <div v-if="edit_enable" class="mr-12" style="width: 100%;">
                                                            <multiselect class="diginew-multiselect diginew-multiselect-underline multiselect-color" name="time_zone" label="value" :show-labels="false" :searchable="true" :loading="loading" :options="time_zone_options" v-model="edit_user_data.time_zone" placeholder="Select Time Zone" style="padding-bottom: 0px !important">
                                                                <template slot="noOptions">
                                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                                </template>
                                                                <template slot="noResult">
                                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                </template>
                                                            </multiselect>
                                                        </div>
                                                        <div v-if="!edit_enable">
                                                            <input class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.time_zone.value">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="mt-7">
                                                <div class="d-flex align-items-center new-contactView ">
                                                    <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Tax ID</div>
                                                    <div class="w-100" style="position: relative;">
                                                        <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" v-if="edit_tax_id" >
                                                            <div class="pointer" @click="resetValue('tax_id')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="updateInfo('tax_id')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div> -->
                                                       
                                                        <input name='tax_id' class="input-field-underline" maxlength="30" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.tax_id">
                                                        <div class="text-right" v-if="edit_enable" style="position: absolute;right: 0px;">
                                                            <span class="input-field-length">{{ edit_user_data.tax_id.length }}/30</span>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="mt-7 mb-7">
                                            <div class="d-flex align-items-center new-contactView align-items-start" style="position: relative;">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">About</div>
                                                <div class="w-100" :class="{'pr-15':edit_about, 'pr-0':!edit_about}">
                                                   
                                                    <input class="input-field-underline" style="padding-top: 6px !important; padding-right: 10px !important;resize: none;" maxlength="100" :readonly="!edit_enable" v-model="edit_user_data.about" />
                                                    <div class="text-right" v-if="edit_enable" style="position: absolute;right: 0px;">
                                                        <span class="input-field-length">{{ edit_user_data.about.length }}/100</span>
                                                    </div>
                                                    
                                                </div>
                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="top: 0px; bottom: auto; right: 0;" v-if="edit_about" >
                                                    <div class="pointer" @click="resetValue('about')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateInfo('about')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="create-company-header d-flex align-items-center justify-content-between mt-6 ml-2">
                                        <span class="fs-14 text-white">Fiscal Year</span>
                                        <span class="pointer" @click="expandTabs('fiscal_year')">
                                            <i class="text-white" :class="{'icon-chevron-double-up':fiscal_year_expand, 'icon-chevron-double-down':!fiscal_year_expand}" style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div v-if="fiscal_year_expand" style="padding: 0 10px;">
                                        <div class="row mt-3">
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="mt-6">
                                                    <div class="d-flex align-items-center new-contactView ">
                                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Fiscal Year</div>
                                                        <div class="w-100" style="position: relative;">
                                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_fiscal_year_name" >
                                                                <div class="pointer" @click="resetValue('fiscal_year_name')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                                </div>
                                                                <div class="ml-2 pointer" @click="updateInfo('fiscal_year_name')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                                </div>
                                                            </div> -->
                                                            <div v-if="edit_enable">
                                                                <div class="fiscalYearDropdown input-field-underline" style="background-color: transparent !important; width: 100%;">
                                                                    <el-dropdown class="w-100" trigger="click" :hide-on-click="hide_el_dropdown" @visible-change="dropdownTriggered">
                                                                        <span class="filters mr-1 w-100">
                                                                            <span v-if="edit_user_data.fiscal_year_name" style="color: #303031 !important;font-size: 13px;font-family: 'Poppins', sans-serif !important;">{{edit_user_data.fiscal_year_name}}</span>
                                                                            <span v-else>Fiscal Year</span>
                                                                            <span style="position:absolute;right:0px">
                                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg" alt="">
                                                                            </span>
                                                                        </span>
                                                                        <el-dropdown-menu slot="dropdown" class="fiscal-year-el">
                                                                            <el-dropdown-item class="d-flex justify-content-between align-items-center">
                                                                                <i class="icon-chevron-double-left m-0" @click="yearPrev"></i>
                                                                                <span style="font-size: 12px; font-weight: 400">{{years_to_set[0]}} - {{years_to_set[5]}}</span>
                                                                                <i class="icon-chevron-double-right m-0" @click="yearNext"></i>
                                                                            </el-dropdown-item>
                                                                            <el-dropdown-item class="d-flex flex-column justify-content-center align-items-center">
                                                                                <span :class="{ 'selectedYear':yearSelected == `${years_to_set[0]}-${years_to_set[1]}` }" @click="yearSelected = years_to_set[0] + '-' + years_to_set[1]">{{years_to_set[0]}} - {{years_to_set[1]}}</span>
                                                                                <span :class="{ 'selectedYear':yearSelected == `${years_to_set[1]}-${years_to_set[2]}` }" @click="yearSelected = years_to_set[1] + '-' + years_to_set[2]">{{years_to_set[1]}} - {{years_to_set[2]}}</span>
                                                                                <span :class="{ 'selectedYear':yearSelected == `${years_to_set[2]}-${years_to_set[3]}` }" @click="yearSelected = years_to_set[2] + '-' + years_to_set[3]">{{years_to_set[2]}} - {{years_to_set[3]}}</span>
                                                                                <span :class="{ 'selectedYear':yearSelected == `${years_to_set[3]}-${years_to_set[4]}` }" @click="yearSelected = years_to_set[3] + '-' + years_to_set[4]">{{years_to_set[3]}} - {{years_to_set[4]}}</span>
                                                                                <span :class="{ 'selectedYear':yearSelected == `${years_to_set[4]}-${years_to_set[5]}` }" @click="yearSelected = years_to_set[4] + '-' + years_to_set[5]">{{years_to_set[4]}} - {{years_to_set[5]}}</span>
                                                                                <a href="javascript:;" class="btn btn-new-primary btn-xsm mt-2 mb-4" @click="yearClicked()">Apply</a>
                                                                            </el-dropdown-item>
                                                                        </el-dropdown-menu>
                                                                    </el-dropdown>
                                                                </div>
                                                            </div>
                                                            <div v-if="!edit_enable">
                                                                <input class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.fiscal_year_name">
                                                            </div>
                                                        </div>
                                                        <!-- <span v-if="edit_fiscal_year_name" class="pointer" >
                                                            <el-tooltip :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark" content="The value is auto-filled and configurable in Settings. Click here to go to Settings" placement="top">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2" style="width:15px" alt="">
                                                            </el-tooltip>
                                                        </span> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="mt-6">
                                                    <div class="d-flex align-items-center new-contactView ">
                                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Start Date</div>
                                                        <div class="w-100" style="position: relative;">
                                                            <div v-if="edit_enable" style="position: relative; width: 100%;">
                                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="z-index: 2;right: -40px;bottom: 10px;" >
                                                                    <div class="pointer" @click="resetValue('start_date')">
                                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                                    </div>
                                                                    <div class="ml-2 pointer" @click="updateInfo('start_date')">
                                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                                    </div>
                                                                </div> -->
                                                                <date-picker class="profile-date-picker mx-input-date calendar-edit-per input-field-underline pt-0 pb-0 pr-0" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" v-model="fiscal_start_date" value-type="format" :popup-style="{ top: '100%', left: 0}" :append-to-body="false" lang="en" 
                                                                    :clearable="false"
                                                                    :editable="false"
                                                                    :not-after="end_year"
                                                                    :not-before="start_year"
                                                                    type="date" format="DD/MM/YYYY" style="width:100%;"></date-picker>
                                                            </div>
                                                            <div v-if="!edit_enable">
                                                                <input class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="fiscal_start_date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-7">
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="mt-7">
                                                    <div class="d-flex align-items-center new-contactView ">
                                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">End Date</div>
                                                        <div class="w-100" style="position: relative;">
                                                            <div v-if="edit_enable" style="position: relative;">
                                                                <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="z-index: 2;right: -40px;bottom: 10px;" >
                                                                    <div class="pointer" @click="resetValue('end_date')">
                                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                                    </div>
                                                                    <div class="ml-2 pointer" @click="updateInfo('end_date')">
                                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                                    </div>
                                                                </div> -->
                                                                
                                                                <date-picker class="profile-date-picker calendar-edit-per mx-input-date input-field-underline pt-0 pb-0 pr-0" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" v-model="fiscal_end_date" value-type="format" :popup-style="{ top: '100%', left: 0}" :append-to-body="false" lang="en"
                                                                    :clearable="false"
                                                                    :editable="false"
                                                                    :not-after="end_year"
                                                                    :not-before="start_year"
                                                                    type="date" format="DD/MM/YYYY" style="width:100%"></date-picker>
                                                            </div>
                                                            <div v-if="!edit_enable">
                                                                <input class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="fiscal_end_date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="create-company-header d-flex align-items-center justify-content-between mt-4 ml-2">
                                        <span class="fs-14 text-white">Communication Information</span>
                                        <span class="pointer" @click="expandTabs('communication_info')">
                                            <i class="text-white" :class="{'icon-chevron-double-up':communication_info_expand, 'icon-chevron-double-down':!communication_info_expand}" style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div v-if="communication_info_expand" style="padding: 0 10px;">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="mt-7">
                                                    <div class="d-flex align-items-center new-contactView ">
                                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Primary E- mail</div>
                                                        <div class="w-100" style="position: relative;">
                                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_primary_email" >
                                                                <div class="pointer" @click="resetValue('primary_email')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                                </div>
                                                                <div class="ml-2 pointer" @click="updateInfo('primary_email')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                                </div>
                                                            </div> -->
                                                            <input name='primary_email' v-validate="'email'" class="input-field-underline" maxlength="30" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.primary_email">
                                                            <div class="text-right" v-if="edit_enable" style="position: absolute;right: 0px;">
                                                                <span class="input-field-length">{{ edit_user_data.primary_email.length }}/30</span>
                                                            </div>
                                                            <div class="d-flex justify-content-between align-items-center" >
                                                                <span class="text-danger fs-13" v-show="errors.has('primary_email')">
                                                                    Enter valid email address
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="mt-7" v-for="(alt_email, alt_email_index) in edit_user_data.alternate_email" :key="'alt_email'+alt_email_index">
                                                    <div class="d-flex align-items-center new-contactView ">
                                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Alternate E-mail</div>
                                                        <div class="w-100" style="position: relative;">
                                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_alternate_email && (alt_email_index == selected_alt_email_index)" >
                                                                <div class="pointer" @click="resetValue('alternate_email', alt_email_index)">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                                </div>
                                                                <div class="ml-2 pointer" @click="updateInfo('alternate_email')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                                </div>
                                                            </div> -->
                                                            <input name='alternate_email' maxlength="30" v-validate="'email'" class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.alternate_email[alt_email_index]">
                                                            <div class="text-right" v-if="edit_enable" style="position: absolute;right: 0px;">
                                                                <span class="input-field-length">{{ edit_user_data.alternate_email[alt_email_index].length }}/30</span>
                                                            </div>
                                                            <!-- </el-tooltip> -->
                                                            <div class="d-flex justify-content-between align-items-center" >
                                                                <span class="text-danger fs-13" v-show="errors.has('alternate_email')">
                                                                    Enter valid email address
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="mt-7">
                                                    <div class="d-flex align-items-center new-contactView ">
                                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Primary Phone Number</div>
                                                        <div class="w-100" style="position: relative;">
                                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_primary_phone" >
                                                                <div class="pointer" @click="resetValue('primary_phone')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                                </div>
                                                                <div class="ml-2 pointer" @click="updateInfo('primary_phone')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                                </div>
                                                            </div> -->
                                                            <div v-if="edit_enable" >
                                                                <vue-tel-input
                                                                    name="primary_phone"
                                                                    :enabledCountryCode="false"
                                                                    :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                                    class="tele-contact-border-radius input-field-underline w-100" ref="telInput"
                                                                    placeholder="Enter your alternate Phone Number"
                                                                    :defaultCountry="edit_user_data.primary_phone.country_code"
                                                                    @onInput="primaryPhone($event)"
                                                                    v-validate="{min:10}"
                                                                    v-model="edit_user_data.primary_phone.phone_number">
                                                                </vue-tel-input>
                                                            </div>
                                                            <div v-if="!edit_enable">
                                                                <input class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.primary_phone.phone_number">
                                                            </div>
                                                            <div class="d-flex justify-content-between align-items-center" >
                                                                <span class="text-danger fs-13" v-show="errors.has('primary_phone')">
                                                                    Enter valid phone number
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="mt-7" v-for="(alt_phone, alt_phone_index) in edit_user_data.alternate_phone" :key="'alt_phone'+alt_phone_index">
                                                    <div class="d-flex align-items-center new-contactView ">
                                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Alternate Phone Number</div>
                                                        <div class="w-100" style="position: relative;">
                                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_alternate_phone && (alt_phone_index == selected_alt_phone_index)" >
                                                                <div class="pointer" @click="resetValue('alternate_phone', alt_phone_index)">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                                </div>
                                                                <div class="ml-2 pointer" @click="updateInfo('alternate_phone')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                                </div>
                                                            </div> -->
                                                            <div v-if="edit_enable" >
                                                                <vue-tel-input
                                                                    :name="'alternate_phone'+alt_phone_index"
                                                                    :enabledCountryCode="false"
                                                                    :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                                    class="tele-contact-border-radius input-field-underline w-100" ref="telInput"
                                                                    placeholder="Enter your alternate Phone Number"
                                                                    :defaultCountry="edit_user_data.alternate_phone[alt_phone_index].country_code"
                                                                    data-vv-as="Alternate Phone"
                                                                    @onInput="alternatePhone($event, alt_phone_index)"
                                                                    v-validate="{min:10}"
                                                                    v-model="edit_user_data.alternate_phone[alt_phone_index].phone_number">
                                                                </vue-tel-input>
                                                            </div>
                                                            
                                                            <div v-if="!edit_enable">
                                                                <input name='alternate_email' maxlength="30" class="input-field-underline" style="padding-top: 6px !important;" :readonly="!edit_enable" v-model="edit_user_data.alternate_phone[alt_phone_index].phone_number">
                                                            </div>
                                                            <div class="d-flex justify-content-between align-items-center" >
                                                                <span class="text-danger fs-13" v-show="errors.has('alternate_phone'+alt_phone_index)">
                                                                    Enter valid phone number
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-8 col-md-12 col-sm-12">
                                                <div class="mt-7" width="100% !important">
                                                    <div class="d-flex align-items-center new-contactView ">
                                                        <!-- <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Social Media</div> -->
                                                        <div class="w-100" style="position: relative;">
                                                            <div class="" v-for="(media, socialindex) in edit_user_data.social_media"
                                                                :key="'media'+socialindex">
                                                                <div class="d-flex align-items-center new-contactView align-items-center">
                                                                    <div :class="{'v-hidden':socialindex > 0}" class="text-secondary profile-content-left-text profile-content-left-text-new">
                                                                        Social Media
                                                                        <sup class="fs-14 ml-1 mandatory">
                                                                            <el-tooltip class="item" effect="dark"
                                                                                content="Please enter full social media url."
                                                                                placement="right">
                                                                                <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                                                            </el-tooltip>
                                                                        </sup>
                                                                    </div>
                                                                    <!-- style="border-bottom: solid 0.5px #cdd2da !important;margin-bottom: -4px !important;" -->
                                                                    <div class="d-flex align-items-center w-160px">
                                                                        <img v-if="edit_user_data.social_media[socialindex].account_type.type === 'Facebook'"
                                                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/Facebook.svg"
                                                                            style="margin-right: 10px;width: 20px;padding-bottom: 2px !important;padding-top: 6px !important;" />
                                                                        <img v-if="edit_user_data.social_media[socialindex].account_type.type === 'Twitter'"
                                                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/twitterr.svg"
                                                                            style="margin-right: 10px;width: 20px;padding-bottom: 2px !important;padding-top: 6px !important;" />
                                                                        <img v-if="edit_user_data.social_media[socialindex].account_type.type === 'Linkedin'"
                                                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/LinkedIn.svg"
                                                                            style="margin-right: 10px;width: 20px;padding-bottom: 2px !important;padding-top: 6px !important;" />
                                                                        <img v-if="edit_user_data.social_media[socialindex].account_type.type === 'Reddit'"
                                                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/Reddit.svg"
                                                                            style="margin-right: 10px;width: 20px;padding-bottom: 2px !important;padding-top: 6px !important;" />
                                                                        <img v-if="edit_user_data.social_media[socialindex].account_type.type === 'Instagram'"
                                                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/insta.svg"
                                                                            style="margin-right: 10px;width: 20px;padding-bottom: 2px !important;padding-top: 6px !important;" />
                                                                        <multiselect v-validate="'required'" v-model="edit_user_data.social_media[socialindex].account_type"
                                                                            class="diginew-multiselect diginew-multiselect-underline pointer multiselect-color" id="ajax"
                                                                            @open="fetchSocialDropdownsData('social_media', socialindex)"
                                                                            label="type"
                                                                            :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant' || !edit_enable"
                                                                            style="padding-bottom: 0px !important;width: 98px !important;min-width: 98px;padding-left: 0px !important;padding-right: 0px !important;"
                                                                            :loading="media.dropdown_loading"
                                                                            :options="social_media_type_options"
                                                                            :show-labels="false"
                                                                            :multiple="false" :searchable="true" :internal-search="true"
                                                                            :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                                            :max-height="200" :show-no-results="true" :hide-selected="false">
                                                                            <template slot="noOptions">
                                                                                <div class="text-secondary text-center fw-600">No Social Media Types Found</div>
                                                                            </template>
                                                                            <template slot="noResult">
                                                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                            </template>
                                                                            <template slot="afterList">
                                                                                    <div v-observe-visibility="reachedEndOfSocialListDropdown" />
                                                                                </template>
                                                                        </multiselect>
                                                                    </div>
                                                                    <div class="d-flex align-items-center ml-2 w-100">
                                                                        <div class="w-100 p-2" style="position: relative;">
                                                                            <div class="position-relative">
                                                                                <input class="input-field-underline"
                                                                                    style="padding-top: 6px !important"
                                                                                    :readonly="!edit_enable"
                                                                                    :name="'social_account_url'+socialindex"
                                                                                    data-vv-as="Social URL"
                                                                                    v-validate="'url'"
                                                                                    @change="validateSocialUrl($event.target.value, socialindex, edit_user_data.social_media[socialindex].account_type)"
                                                                                    v-model="edit_user_data.social_media[socialindex].account_url">
                                                                                <div v-if="edit_enable" class="d-flex justify-content-end align-items-center new-action-btns"
                                                                                    style="position: absolute;top:18px;right:0px">
                                                                                    <div v-if="socialindex >= 0" class="d-flex align-items-center ml-5 pointer" @click="deleteSocialMedia(media.id, socialindex)">
                                                                                        <i class="icon icon-minus-circle text-danger" style="font-size: 19px !important;"></i>
                                                                                    </div>
                                                                                    <div v-if="edit_user_data.social_media.length == socialindex+1 && edit_user_data.social_media[socialindex].account_url != ''" class="d-flex align-items-center pointer" @click="addSocialMedia(socialindex)">
                                                                                        <i class="icon-plus-circle" style="color: #4CAF50 !important;font-size: 18px;"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <!-- <div class="invalid-feedback-form font-weight-bold" style="margin-top: 4px !important;color: #ff3b3b; position: absolute;" v-show="errors.has('social_account_url'+socialindex)">{{ errors.first("social_account_url"+socialindex) }}</div> -->
                                                                            <div class="invalid-feedback-form font-weight-bold" v-if="edit_user_data.social_media[socialindex].is_url_invalid">
                                                                                Please enter complete and valid Social Url.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_social_media" >
                                                                <div class="pointer" @click="resetValue('social_media')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                                </div>
                                                                <div class="ml-2 pointer" @click="updateInfo('social_media')">
                                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                                </div>
                                                            </div>
                                                            <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                                    content="Click to edit"
                                                                    placement="top">
                                                            <input name='social_media' v-validate="'url'" class="input-field-underline" @focus="editUser('social_media')" style="padding-top: 6px !important;" :readonly="!edit_social_media" v-model="edit_user_data.social_media">
                                                            </el-tooltip>
                                                            <div class="d-flex justify-content-between align-items-center position-absolute" >
                                                                <span class="text-danger fs-13" v-show="errors.has('social_media')">
                                                                    Enter valid url
                                                                </span>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="edit_enable" class="d-flex align-items-center justify-content-center pb-8">
                        <button class="btn btn-outline-secondary" :disabled="upload_in_progress || save_api_call_in_progress || logo_image_upload_inprogress" @click="editDisable" style="width: 120px;border-radius: 5px;">Cancel</button>
                        <button class="btn btn-new-success ml-5" :disabled="upload_in_progress || save_api_call_in_progress || logo_image_upload_inprogress || fields_not_changed" @click="checkValidate" style="width: 120px;border-radius: 5px;">Save
                            <hollow-dots-spinner v-if="upload_in_progress || save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import { HollowDotsSpinner } from "epic-spinners";
    import moment from "moment";
    // import { ValidationProvider } from 'vee-validate';
    // import {
    //     User
    // } from "./mixins/user";
    import { SweetModal } from 'sweet-modal-vue';
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    import DatePicker from 'vue2-datepicker'
    // import currency from "./json/currency.json";
    import Element from 'element-ui'
    import {
        EventBus
    } from "../eventBus/nav-bar-event.js";
    import timezone from '../json/timezone.json';
    import currencies from '../json/currency.json';
    import companies from '../mixins/companies.js';
    import globals from '../globals.js';
    import axios from 'axios';
    export default {
        components:{
            DatePicker,
            Element,
            HalfCircleSpinner,
            SweetModal,
            HollowDotsSpinner
        },
        data() {
            return {
                upload_in_progress: false,
                save_api_call_in_progress: false,
                edit_enable:false,
                maxlengthcustomname:15,
                loading: false,
                warning_msg: '',
                success_msg: '',
                selected_alt_email_index: 0,
                selected_alt_phone_index: 0,
                edit_user_data: {
                    company_name: '',
                    industry_type: '',
                    no_of_employees: '',
                    domain_name: '',
                    date_of_incorporation: '',
                    time_zone: '',
                    currency: {},
                    tax_id: '',
                    about: '',
                    fiscal_year_name: '',
                    job_description: '',
                    primary_email: '',
                    alternate_email: [],
                    primary_phone: {
                        phone_type: 'Primary',
                        phone_number: '',
                        dial_code: '',
                        country_code: '',
                    },
                    alternate_phone: [],
                    social_media: '',
                },
                fiscal_start_date: '',
                fiscal_end_date: '',
                edit_company_name: false,
                edit_industry_type: false,
                edit_domain_name: false,
                edit_date_of_incorporation: false,
                edit_time_zone: false,
                edit_no_of_employees: false,
                edit_currency: false,
                edit_tax_id: false,
                edit_about: false,
                edit_fiscal_year_name: false,
                edit_fiscal_start_date: false,
                edit_fiscal_end_date: false,
                edit_tax_id: false,
                edit_primary_email: false,
                edit_alternate_email: false,
                edit_primary_phone: false,
                edit_alternate_phone: false,
                edit_social_media: false,
                // valueqr: "https://example.com",
                // size: 90,
                user_data: {},
                ajax_call_in_progress: false,
                industry_type_options: [],
                currency_options: currencies,
                time_zone_options: timezone,
                hide_el_dropdown: false,
                years_to_set: [],
                noFiscalYear: [],
                showFiscalError: false,
                yearSelected: "",
                company_info_expand: true,
                fiscal_year_expand: true,
                communication_info_expand: true,
                skip: 0,
                limit: 10,
                isVisible: false,
                count: 0,
                scrollable: false,
                temp_dropdown_options: [],
                setting_type: '',
                dropdown_loading: false,
                social_media_type_options: [],
                social_media_index: 0,
                which_type: '',
                dropdown_index: 0,
                comp_data: {
                    company_name: '',
                    cover_image: '',
                    logo: '',
                    percentage_completed: 0,
                    to_be_filled: []
                },
                logo_image_upload_inprogress: false,
                stringified_response: "",
                start_year: '',
                end_year: ''
            };
        },
        mixins: [companies],
        methods: {
            blockAlphabets(event) {
                const charCode = event.which ? event.which : event.keyCode;
                // Allow only numbers (0-9) and control keys (backspace, tab, etc.)
                if (!(charCode >= 48 && charCode <= 57) && charCode !== 8 && charCode !== 9) {
                    event.preventDefault();
                }
            },
            validateSocialUrl(value, index, account_type) {
                if(value) {
                    if(account_type.type == 'Facebook') {
                        let fb_url_regex = /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/
                        this.edit_user_data.social_media[index].is_url_invalid = !fb_url_regex.test(value);
                    } else if(account_type.type == 'Instagram') {
                        let ig_url_regex = /(?:http|https?:\/\/)?(?:www\.)?(instagram.com|instagr.am|instagr.com)\/(\w+)/
                        this.edit_user_data.social_media[index].is_url_invalid = !ig_url_regex.test(value);
                    } else if(account_type.type == 'Twitter') {
                        let twtr_url_regex = /(?:http|https?:\/\/)?(?:www\.)?(twitter.com)\/(\w+)/
                        this.edit_user_data.social_media[index].is_url_invalid = !twtr_url_regex.test(value);
                    } else if(account_type.type == 'Linkedin') {
                        let lnkn_url_regex = /(?:https?:\/\/)?(?:www\.)?(linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/
                        this.edit_user_data.social_media[index].is_url_invalid = !lnkn_url_regex.test(value);
                    } else if(account_type.type == 'Reddit') {
                        let redt_url_regex = /(?:http|https?:\/\/)?(?:www\.)?(reddit.com)\/(\w+)/
                        this.edit_user_data.social_media[index].is_url_invalid = !redt_url_regex.test(value);
                    }
                } else {
                    this.edit_user_data.social_media[index].is_url_invalid = false
                }
            },
            moment(date) {
                return moment(date);
            },
            expandTabs(type) {
                if(type == 'company_info') {
                    this.company_info_expand = !this.company_info_expand;
                } else if(type == 'fiscal_year') {
                    this.fiscal_year_expand = !this.fiscal_year_expand;
                } else if(type == 'communication_info') {
                    this.communication_info_expand = !this.communication_info_expand;
                }
            },
            primaryPhone({number,isValid,country}) {
                this.edit_user_data.primary_phone.phone_number = number.national;
                this.edit_user_data.primary_phone.dial_code = country.dialCode;
                this.edit_user_data.primary_phone.country_code = country.iso2;
            },
            alternatePhone({number,isValid,country}, index) {
                this.edit_user_data.alternate_phone[index].phone_number = number.national;
                this.edit_user_data.alternate_phone[index].dial_code = country.dialCode;
                this.edit_user_data.alternate_phone[index].country_code = country.iso2;
            },
            yearNext() {
                let year = this.years_to_set[5];
                this.years_to_set = [];
                for (let i = 0; i < 6; i++) {
                    this.years_to_set.push(year + i);
                }
            },
            yearPrev() {
                let year = this.years_to_set[0];
                this.years_to_set = [];
                for (let i = 0; i < 6; i++) {
                    this.years_to_set.push(year - i);
                }
                this.years_to_set.reverse();
            },
            yearClicked() {
                this.hide_el_dropdown = true;
                this.edit_user_data.fiscal_year_name = this.yearSelected;
                if(this.edit_user_data.fiscal_year_name){
                    let fisical_array = this.edit_user_data.fiscal_year_name.split('-')
                    this.start_year = '01/01/'+fisical_array[0]
                    this.end_year = '12/31/'+fisical_array[1]
                }
            },
            dropdownTriggered(event) {
                if (event) {
                    this.hide_el_dropdown = false;
                }
            },
            async deleteSocialMedia(id, index) {
                if(id) {
                    try {
                        this.$http.delete(globals.AUTH_SERVICE + `/company/social_media/${id}`).then(response => {
                            if(response.data.status_id == 1) {
                                this.success_msg = response.data.message
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                    this.getCompData();
                                }, 2000);
                            }
                        }).catch(error => {
                            this.warning_msg = error.response.data.reason;
                            this.$refs.warning_modal.open();
                        });
                    } catch (error) {
                        this.warning_msg = error;
                        this.$refs.warning_modal.open();
                    }
                }
                else {
                    this.edit_user_data.social_media.splice(index,1);
                }
            },
            addSocialMedia() {
                this.edit_user_data.social_media.push(
                    {
                        account_type: {
                            type: 'Facebook',
                            icon_url: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/Facebook.svg'
                        },
                        account_url: '',
                        is_url_invalid: false
                    }
                );
            },
            triggerFileUpload() {
                document.getElementById('fileprofile').click();
            },
            removeCompanyImage() {
                this.$swal({
                    title: 'Alert',
                    text: "Are you sure want to Remove ?",
                    type: 'warning',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText:'YES',
                    cancelButtonText:'NO'
                }).then((result) => {
                    if (result.value) {
                        this.edit_user_data.logo = '';
                        this.$forceUpdate();
                    }
                })
            },
            onUserProfile(e) {
                var input = e.target;
                if (input.files && input.files[0]) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                        name: input.files[0].name,
                        size: JSON.stringify(input.files[0].size),
                        source: source,
                        progress: 0,
                        created_time: new Date(),
                        error: "",
                    };
                    this.getResumableURLprofile(input.files[0], attachment, "profile_pic");
                }
            },
            getResumableURLprofile(file, attachment, type) {
                this.logo_image_upload_inprogress = true;
                let data = {
                    file: attachment.name,
                };
                data = new FormData();
                data.append("file", file);
                this.$http
                    .post("https://cloudapis.digicollect.com/external/upload_file", data)
                    .then(response => {
                        if (response.data.status_id == 1) {
                            // this.updatePersonalData(response.data.url, type);
                            this.edit_user_data.logo = response.data.url
                            this.$forceUpdate();
                        }
                        this.logo_image_upload_inprogress = false;
                    })
                    .catch(error => {
                        // this.error = "Error Uploading"
                        this.logo_image_upload_inprogress = false;
                    });
            },
            editEnable(){
                if(this.loggedInUser.user_role == 'owner' || this.loggedInUser.user_role == 'super admin' || this.loggedInUser.user_role == 'super_admin') {
                    this.edit_enable = true;
                    this.$emit("editValue", this.edit_enable)
                }
            },
            editDisable(){
                this.edit_enable = false;
                this.$emit("editValue", this.edit_enable)
            },
            checkValidate(){
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.updateInfo();
                    }
                })
            },
            async updateInfo(index) {
                try {
                    let params = {
                    };
                        params.name = this.edit_user_data.company_name
                        params.logo = this.edit_user_data.logo
                   
                        params.industry_type = this.edit_user_data.industry_type
                    
                        params.date_of_incorporation = this.edit_user_data.date_of_incorporation ? this.edit_user_data.date_of_incorporation.split("/")[2]+"-"+this.edit_user_data.date_of_incorporation.split("/")[1]+"-"+this.edit_user_data.date_of_incorporation.split("/")[0] : '';
                    
                        params.no_of_employees = this.edit_user_data.no_of_employees
                    
                        params.time_zone = this.edit_user_data.time_zone.value
                    
                        params.currency = this.edit_user_data.currency
                    
                        params.fiscal_year_name = this.edit_user_data.fiscal_year_name
                   
                        params.start_date = this.fiscal_start_date ? this.fiscal_start_date.split("/")[2]+"-"+this.fiscal_start_date.split("/")[1]+"-"+this.fiscal_start_date.split("/")[0] : '';
                    
                        params.end_date = this.fiscal_end_date ? this.fiscal_end_date.split("/")[2]+"-"+this.fiscal_end_date.split("/")[1]+"-"+this.fiscal_end_date.split("/")[0] : ''
                  
                        params.tax_id = this.edit_user_data.tax_id
                    
                        params.about = this.edit_user_data.about
                   
                        params.info_email = this.edit_user_data.primary_email
                    
                        params.alternate_email = this.edit_user_data.alternate_email 
                        var alternateEmail = [];
                            this.edit_user_data.alternate_email.forEach((el, index)=>{
                                if (el != "") {
                                    alternateEmail.push(el)
                                }
                            })
                        params.alternate_email = alternateEmail

                        params.primary_number = this.edit_user_data.primary_phone
                   
                        params.alternate_phone_numbers = this.edit_user_data.alternate_phone
                        var alternatePhNumber = [];
                            this.edit_user_data.alternate_phone.forEach((el, index)=>{
                                if (this.edit_user_data.alternate_phone[index].phone_number != "") {
                                    alternatePhNumber.push(el)
                                }
                            })
                        params.alternate_phone_numbers = alternatePhNumber


                        params.social_media = this.edit_user_data.social_media
                        // this.edit_user_data.social_media = [];
                        var socialMedia = [];
                            this.edit_user_data.social_media.forEach((el, index)=>{
                                socialMedia = { account_type: this.edit_user_data.social_media[index].account_type.type,
                                account_url: this.edit_user_data.social_media[index].account_url,
                                icon_url: this.edit_user_data.social_media[index].account_type.icon_url,
                                id: this.edit_user_data.social_media[index].id
                                }
                                this.edit_user_data.social_media[index] = socialMedia
                                // user_id: this.loggedInUser.user_id
                            })
                            params.social_media = this.edit_user_data.social_media
                        // if(this.edit_user_data.social_media[index].id ) {
                        //     params.social_media = [
                        //         {
                        //             account_type: this.edit_user_data.social_media[index].account_type.type,
                        //             account_url: this.edit_user_data.social_media[index].account_url,
                        //             icon_url: this.edit_user_data.social_media[index].account_type.icon_url,
                        //             id: this.edit_user_data.social_media[index].id,
                        //             user_id: this.loggedInUser.user_id
                        //         }
                        //     ];
                        // } else {
                        //     params.social_media = [
                        //         {
                        //             account_type: this.edit_user_data.social_media[index].account_type.type,
                        //             icon_url: this.edit_user_data.social_media[index].account_type.icon_url,
                        //             account_url: this.edit_user_data.social_media[index].account_url,
                        //         }
                        //     ];
                        // }
                        // params.social_media = [this.edit_user_data.social_media]
                    this.save_api_call_in_progress = true
                    let response = await this.editCompany(params, this.$route.params.company_id);
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getCompData();
                        }, 2000);
                        this.save_api_call_in_progress = false
                        // EventBus.$emit('personal_info_data_update')
                    }
                }
                catch(err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                    this.save_api_call_in_progress = false
                }
            },
            async getCompData() {
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getCompanyById(this.$route.params.company_id);
                    if (response.status_id == 1) {
                        localStorage.setItem('comp_data', JSON.stringify(response.response));
                        // this.comp_data = JSON.parse(JSON.stringify(response.response))
                        this.edit_enable = false;
                        this.user_data = response.response;
                        this.edit_user_data.company_name = this.user_data.name;
                        this.edit_user_data.logo = this.user_data.logo;
                        this.edit_user_data.industry_type = this.user_data.industry_type;
                        this.edit_user_data.date_of_incorporation = this.user_data.date_of_incorporation//? this.user_data.date_of_incorporation.split('-')[2]+'/'+this.user_data.date_of_incorporation.split('-')[1]+'/'+this.user_data.date_of_incorporation.split('-')[0] : '';
                        if(this.edit_user_data.date_of_incorporation == ''){
                            this.edit_user_data.date_of_incorporation = this.user_data.creation_time
                        }
                        this.edit_user_data.about = this.user_data.about;
                        this.edit_user_data.job_description = this.user_data.job_description;
                        this.edit_user_data.gender = this.user_data.gender;
                        this.edit_user_data.custom_gender = this.user_data.custom_gender;
                        this.edit_user_data.user_access = this.user_data.user_access;
                        this.edit_user_data.tax_id = this.user_data.tax_id;
                        if(this.edit_user_data.gender == 'custom' || this.edit_user_data.gender == 'Custom') {
                            // this.custom_gender_active = true;
                            this.edit_user_data.gender == 'Custom'
                        }
                        this.edit_user_data.fiscal_year_name = this.user_data.fiscal_year_name;
                        if(this.edit_user_data.fiscal_year_name){
                            let fisical_array = this.edit_user_data.fiscal_year_name.split('-')
                            this.start_year = '01/01/'+fisical_array[0]
                            this.end_year = '12/31/'+fisical_array[1]
                        }
                        this.fiscal_start_date = this.user_data.start_date ? this.user_data.start_date.split('-')[2]+'/'+this.user_data.start_date.split('-')[1]+'/'+this.user_data.start_date.split('-')[0] : '';
                        this.fiscal_end_date = this.user_data.end_date ? this.user_data.end_date.split('-')[2]+'/'+this.user_data.end_date.split('-')[1]+'/'+this.user_data.end_date.split('-')[0] : '';
                        this.edit_user_data.currency = this.user_data.currency;
                        this.edit_user_data.domain_name = this.user_data.domain;
                        this.edit_user_data.no_of_employees = this.user_data.no_of_employees;
                        this.edit_user_data.time_zone = {
                            label: '',
                            value: this.user_data.time_zone
                        };
                        this.edit_user_data.primary_phone = this.user_data.primary_phone;
                        if(this.user_data.alternate_phone_numbers.length > 0) {
                            this.edit_user_data.alternate_phone = this.user_data.alternate_phone_numbers;
                        } else {
                            this.edit_user_data.alternate_phone = [{
                                phone_type: 'Work',
                                phone_number: "",
                                country_code: "IN",
                                dial_code: "+91"
                            }];
                        }
                        this.edit_user_data.primary_email = this.user_data.info_email;
                        if(this.user_data.alternate_email.length > 0) {
                            this.edit_user_data.alternate_email = this.user_data.alternate_email;
                        } else {
                            this.edit_user_data.alternate_email = [''];
                        }
                        if(this.user_data.social_media.length == 0) {
                            this.edit_user_data.social_media = [{
                                account_type: {
                                    type: 'Facebook',
                                    icon_url: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/Facebook.svg'
                                },
                                account_url: "",
                                is_url_invalid: false
                            }];
                        } else {
                            this.edit_user_data.social_media = [];
                            this.user_data.social_media.forEach((el, index)=>{
                                this.edit_user_data.social_media.push({
                                    account_type: {
                                        type: el.account_type,
                                        icon_url: el.icon_url
                                    },
                                    account_url: el.account_url,
                                    id: el.id,
                                    is_url_invalid: false
                                })
                            })
                        }
                        // this.edit_user_data.social_media = this.user_data.social_media;
                        this.ajax_call_in_progress = false;
                        this.stringified_response = JSON.stringify(this.create_data)
                    }
                }
                catch(err) {
                }
            },
            async fetchSocialDropdownsData(type, index) {
                this.dropdown_index = index;
                this.social_media_type_options = [];
                // if(type == 'social_media') {
                //     this.editUser('social_media', index);
                // }
                //this.dropdown_loading = true;
                this.edit_user_data.social_media[index].dropdown_loading = true;
                this.setting_type = type;
                try {
                    let param = {
                        type: type,
                        skip: 0,
                        limit: 10
                    };
                    let response = await this.getSocialDropdownsData(param);
                    //this.dropdown_loading = false;
                    this.edit_user_data.social_media[index].dropdown_loading = false;
                    if(response.status_id == 1) {
                        if(type == 'social_media') {
                            this.social_media_type_options = response.response;
                        }
                    }
                }
                catch(err) {
                    //this.dropdown_loading = false;
                    this.edit_user_data.social_media[index].dropdown_loading = false;
                }
            },
            async reachedEndOfSocialListDropdown(reached, index) {
                this.isVisible = reached;
                if (reached) {
                    if (this.scrollable == false) {
                        //this.dropdown_loading = true;
                        this.edit_user_data.social_media[this.dropdown_index].dropdown_loading = true;
                        let params = {
                            limit: 10,
                            type: this.setting_type,
                            skip: this.count
                        };
                        let response = await this.getSocialDropdownsData(params);
                        this.temp_dropdown_options = response.response.length;;
                        if (response.skip == this.count) {
                            if (this.temp_dropdown_options > 0) {
                                let tempFiles = response.response;
                                if(this.setting_type == 'social_media') {
                                    tempFiles.forEach(el => {
                                        this.social_media_type_options.push(el);
                                    });
                                }
                                this.count += response.response.length;
                                //this.dropdown_loading = false
                                this.edit_user_data.social_media[this.dropdown_index].dropdown_loading = false;
                            } else {
                                this.scrollable = true
                                //this.dropdown_loading = false
                                this.edit_user_data.social_media[this.dropdown_index].dropdown_loading = false;
                            }
                        }
                        //this.dropdown_loading = false
                        this.edit_user_data.social_media[this.dropdown_index].dropdown_loading = false;
                    }
                }
            },
            async fetchDropdownsData(type, index) {
                // if(type == 'industry_type') {
                //     this.editUser('industry_type');
                // }
                this.loading = true;
                this.setting_type = type;
                try {
                    let param = {
                        type: type,
                        skip: 0,
                        limit: 10
                    };
                    let response = await this.getDropdownsData(param);
                    this.loading = false;
                    if(response.status_id == 1) {
                        if(type == 'industry_type') {
                            this.industry_type_options = response.response;
                        }
                    }
                }
                catch(err) {
                    this.loading = false;
                }
            },
            async reachedEndOfListDropdown(reached) {
                this.isVisible = reached;
                if (reached) {
                    if (this.scrollable == false) {
                        this.isLoading = true;
                        let params = {
                            limit: 10,
                            type: this.setting_type,
                            skip: this.count
                        };
                        let response = await this.getDropdownsData(params);
                        this.temp_dropdown_options = response.response.length;;
                        if (response.skip == this.count) {
                            if (this.temp_dropdown_options > 0) {
                                let tempFiles = response.response;
                                if(this.setting_type == 'industry_type') {
                                    tempFiles.forEach(el => {
                                        this.industry_type_options.push(el);
                                    });
                                }
                                this.count += response.response.length;
                                this.isLoading = false
                            } else {
                                this.scrollable = true
                                this.isLoading = false
                            }
                        }
                        this.isLoading = false
                    }
                }
            },
        },
        // beforeRouteLeave (to, from, next) {
        //     if (this.edit_enable == true) {
        //         let message = "<div class='d-block'>Unsaved Company Information Data would be lost, Do you want to proceed?</div>";
        //         // <img src='/static/images/exclamation-yellow.svg' class='d-block h-100px mx-auto mb-5'/><br>
        //         let options = {
        //             html: true,
        //             loader: false,
        //             reverse: false, // switch the button positions (left to right, and vise versa)
        //             okText: 'Yes',
        //             cancelText: 'No',
        //             animation: 'fade',
        //             type: 'basic',
        //             verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
        //             verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        //             backdropClose: true,
        //         };
        //         this.$dialog.confirm(message, options)
        //         .then(function () {
        //             next();
        //         })
        //         .catch(function () {
        //             next(false);
        //         });
        //     } else {
        //         next()
        //     }
        // },
        mounted() {
            this.getCompData();
        },
        destroyed() {
            EventBus.$on("CompanyName", this.getCompData);
            EventBus.$off("editEnable", this.editEnable);
        },
        created() {
            EventBus.$on("editEnable", this.editEnable);
            EventBus.$on("CompanyName", this.getCompData);
            for (let i = 0; i < 6; i++) {
            let year = new Date().getFullYear();
            this.years_to_set.push(year + i);
            };
            this.fiscalYear = this.years_to_set[0] + "-" + this.years_to_set[1];
            const dict = {
            custom: {
                no_of_employees:{
                    required:() => "Number of employees is required",
                    numeric:() => "Enter valid number",
                },
                social_account_url: {
                    regex: () => "Enter valid Acoount URL."
                },
            }
        }
        this.$validator.localize("en", dict);
        },
        watch: {
            // "edit_user_data.gender": function(newVal,oldVal) {
            //     if (newVal == 'Custom') {
            //         this.custom_gender_active = true;
            //     } else {
            //         this.custom_gender_active = false;
            //     }
            // },
        },
        computed: {
            fields_not_changed(){
                return this.stringified_response == JSON.stringify(this.create_data)
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            create_data() {
                return {
                    "name":this.edit_user_data.company_name,
                    "logo":this.edit_user_data.logo,
                    "industry_type": this.edit_user_data.industry_type,
                    "date_of_incorporation":this.edit_user_data.date_of_incorporation ? this.edit_user_data.date_of_incorporation.split("/")[2]+"-"+this.edit_user_data.date_of_incorporation.split("/")[1]+"-"+this.edit_user_data.date_of_incorporation.split("/")[0] : '',
                    "no_of_employees": this.edit_user_data.no_of_employees,
                    "time_zone": this.edit_user_data.time_zone.value,
                    "currency": this.edit_user_data.currency,
                    "fiscal_year_name": this.edit_user_data.fiscal_year_name,
                    "start_date": this.fiscal_start_date ? this.fiscal_start_date.split("/")[2]+"-"+this.fiscal_start_date.split("/")[1]+"-"+this.fiscal_start_date.split("/")[0] : '',
                    "end_date": this.fiscal_end_date ? this.fiscal_end_date.split("/")[2]+"-"+this.fiscal_end_date.split("/")[1]+"-"+this.fiscal_end_date.split("/")[0] : '',
                    "tax_id": this.edit_user_data.tax_id,
                    "about":this.edit_user_data.about,
                    "info_email": this.edit_user_data.primary_email,
                    "alternate_email": this.edit_user_data.alternate_email,
                    "primary_number": this.edit_user_data.primary_phone,
                    "alternate_phone_numbers": this.edit_user_data.alternate_phone,
                    "social_media": this.edit_user_data.social_media,
                }
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
        beforeDestroy() {
            localStorage.removeItem('comp_data');
        },
    };
</script>
<style scoped>
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
        margin-left: -20px;
        margin-right: -15px;
    }

    .tele-contact-border-radius .selector{
        display: flex !important;
    }
    @media only screen and (max-width: 768px){
        .button-padding{
            margin-bottom: 100px !important;
        }
    }
    .multiselect-color{
        color: #303031 !important;
        font-size: 13px !important;
    }
    .mx-input-date {
        padding: 0px 0px !important;
    }
    .btn-new-success:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
</style>